var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.titulo)+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"fas fa-search","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.banks,"search":_vm.search,"sort-by":"nombre","loading":_vm.loading,"loading-text":"Consultando datos... Espere por favor"},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.date))+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-form',{ref:"form2",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-autocomplete',{attrs:{"items":_vm.categories,"rules":_vm.editedItem.categoryRules,"item-text":"name","item-value":"id","label":"Escoge una Categoria","clearable":"","rounded":"","solo":""},on:{"change":_vm.getBanks},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1)],1)],1),(!_vm.category)?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.selectOption}},[_vm._v("Nuevo Registro")]):_vm._e(),(_vm.category)?_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({ref:"boton2",staticClass:"mb-2",attrs:{"color":"primary","dark":""}},on),[_vm._v("Nuevo Registro")])]}}],null,false,2831889369),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.currencyMask),expression:"currencyMask"}],attrs:{"label":"Cantidad","rules":_vm.editedItem.quantityRules},model:{value:(_vm.editedItem.quantity),callback:function ($$v) {_vm.$set(_vm.editedItem, "quantity", $$v)},expression:"editedItem.quantity"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":_vm.editedItem.dateRules,"label":"Fecha","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.editedItem.date),callback:function ($$v) {_vm.$set(_vm.editedItem, "date", $$v)},expression:"editedItem.date"}},'v-text-field',attrs,false),on))]}}],null,false,383888349),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.editedItem.date),callback:function ($$v) {_vm.$set(_vm.editedItem, "date", $$v)},expression:"editedItem.date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"solo":"","auto-grow":"","outlined":"","rows":"3","label":"Nota","rules":_vm.editedItem.commentRules},model:{value:(_vm.editedItem.comment),callback:function ($$v) {_vm.$set(_vm.editedItem, "comment", $$v)},expression:"editedItem.comment"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)],1)],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"450"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("Desea eliminar a "+_vm._s(_vm.editedItem.name)+"?")]),_c('v-spacer')],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.eliminar}},[_vm._v("Aceptar")])],1)],1)],1)],1)]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("fas fa-edit")]),_c('v-icon',{attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("fas fa-trash-alt")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }